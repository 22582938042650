// ----- Pseudo-element Font Awesome Icons
// Helper mixin, no need to use this one
@mixin fa-pseudo($font-weight, $content) {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight;
  content: $content;
}

// Use for solid icons
@mixin fa-pseudo-solid($content) {
  font: var(--fa-font-solid);
  @include fa-pseudo(900, $content);
}

// Use for regular icons
@mixin fa-pseudo-regular($content) {
  font: var(--fa-font-regular);
  @include fa-pseudo(400, $content);
}

// Use for brand and social media icons
@mixin fa-pseudo-brand($content) {
  font: var(--fa-font-brands);
  @include fa-pseudo(400, $content);
}

// ----- Pulsar Container
$oh-bootstrap-container-xxl: map-get($container-max-widths, 'xxl') - 40px;
$oh-bootstrap-container-xl: map-get($container-max-widths, 'xl') - 40px;
$oh-bootstrap-container-lg: map-get($container-max-widths, 'lg') - 40px;

@function remtopx($rem) {
  @return calc($rem / 1rem) * 16px;
}

@mixin interactive {
  &.focus,
  &.hover,
  &.active,
  &:active,
  &:focus,
  &:hover {
    @content;
  }
}

@mixin pulsar-container(
  $container-width-origin: $oh-bootstrap-container-xxl,
  $container-padding: remtopx($container-padding-x)
) {
  @include make-container($container-padding);
  $container-width: $container-width-origin + $container-padding;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      @if $container-width > $container-max-width {
        max-width: $container-max-width;
      } @else {
        max-width: $container-width;
      }
    }

    @include media-breakpoint-up(xxl) {
      max-width: $container-width;
    }
  }
}

@mixin oh-tiles-grid($column-count, $gap: 2rem) {
  display: grid;
  @include rfs($gap, gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc($oh-bootstrap-container-lg/$column-count - $gap), 1fr)
  );

  @media (min-width: calc($oh-bootstrap-container-xl)) {
    grid-template-columns: repeat($column-count, 1fr);
  }

  @media (max-width: calc($oh-bootstrap-container-xxl/$column-count)) {
    grid-template-columns: 1fr;
  }
}

@mixin svg-shaking-keyframes() {
  @keyframes PreShake {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(25deg);
    }
  }

  @keyframes shake {
    0% {
      transform: rotate(25deg);
    }
    33% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(12deg);
    }
    75% {
      transform: rotate(-12deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

@mixin svg-shaking-card() {
  transition: all 0.3s ease-in-out;
  @include svg-shaking-keyframes;
  svg {
    transform-origin: center center;
    width: fit-content;
  }
  @include interactive {
    transform: scale(1.05);
    svg {
      animation: PreShake 300ms ease-out 0ms 1, shake 500ms ease-in-out 300ms 1;
    }
  }
}

@keyframes PreSlide {
  from {
    transform: translateX(0em);
  }
  to {
    transform: translateX(var(--arrow-slide-start));
  }
}

@keyframes Slide {
  0% {
    transform: translateX(var(--arrow-slide-start));
  }
  50% {
    transform: translateX(var(--arrow-slide-end));
  }
  100% {
    transform: translateX(var(--arrow-slide-start));
  }
}

$oh-arrow-link-margin: 0.5em;
$oh-arrow-slide-start: 0.5em;
$oh-arrow-slide-end: 0.25em;
$oh-arrow-slide-animation: PreSlide 800ms ease-out 0ms 1,
  Slide 1.5s ease-in-out 800ms infinite;

@mixin arrow-link-left($color: $primary) {
  --arrow-slide-start: #{-$oh-arrow-slide-start};
  --arrow-slide-end: #{-$oh-arrow-slide-end};
  text-decoration: none;

  &::before {
    @include fa-pseudo-solid('\f060');
    margin-right: $oh-arrow-link-margin;
    color: $color;
  }

  @include interactive {
    &::before {
      animation: $oh-arrow-slide-animation;
    }
  }
}

@mixin arrow-link-right($color: $primary) {
  --arrow-slide-start: #{$oh-arrow-slide-start};
  --arrow-slide-end: #{$oh-arrow-slide-end};
  text-decoration: none;

  &::after {
    @include fa-pseudo-solid('\f061');
    margin-left: $oh-arrow-link-margin;
    color: $color;
  }

  @include interactive {
    &::after {
      animation: $oh-arrow-slide-animation;
    }
  }
}

@mixin oh-color-background {
  color: var(--text-color);

  %oh-heading {
    color: var(--text-color);
  }

  .btn-primary {
    @include button-variant($white, $white);

    @include interactive {
      @include button-variant($primary, $primary);
    }
  }
}

//Ehrlo - theme mixins
@mixin ehrlo-theme(
  $primary,
  $text-color,
  $feature-1,
  $feature-2,
  $feature-text
) {
  --primary: #{$primary};
  --text-color: #{$text-color};
  --feature-1: #{$feature-1};
  --feature-2: #{$feature-2};
  --feature-text: #{$feature-text};
  --plyr-color-main: #{$primary};

  svg {
    fill: #{$feature-1};
  }

  .referral-program-inner {
    svg {
      fill: $oh-light-green;
    }
  }
}

.sf-toolbar-block svg {
  fill: none !important;
}

.plyr__control svg {
  fill: white !important;
}

:root,
[data-bs-theme='main'] {
  @include ehrlo-theme(
    $oh-primary,
    $white,
    $oh-green,
    $oh-light-green,
    $gray-900
  );

  .icon-image {
    filter: brightness(0) saturate(100%) invert(85%) sepia(10%) saturate(2149%)
      hue-rotate(24deg) brightness(91%) contrast(86%);
  }

  svg {
    fill: $oh-light-green;
  }

  .homepage-banner .feature-bg .feature-bg-img {
    background-image: url(../images/banner-images/leaf-white.svg); // bright leaf image
    background-size: contain;
    background-repeat: no-repeat;
  }
}

[data-bs-theme='counselling'] {
  @include ehrlo-theme(
    $oh-counsel-primary,
    $white,
    $oh-counsel-primary,
    $oh-counsel-feature,
    $gray-900
  );

  .icon-image {
    filter: brightness(0) saturate(100%) invert(19%) sepia(59%) saturate(4782%)
      hue-rotate(169deg) brightness(92%) contrast(104%);
  }

  .homepage-banner .feature-bg .feature-bg-img {
    background-image: url(../images/banner-images/leaf-bg.jpg); // darker leaf image
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    transform: none;
    opacity: 0.3;
    filter: brightness(0.37);
  }

  .super-bg-container {
    position: relative;
    padding-bottom: 1px;

    &::after {
      position: absolute;
      content: '';
      background-image: url(../images/shapes/circle-bg.jpg);
      top: $border-radius-xxl;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      min-height: 100vh;
      height: 100%;
      z-index: -5;
      background-repeat: repeat;
      background-size: 100%;
    }
  }

  .news-section {
    background-color: transparent;
  }
}

[data-bs-theme='early_learning'] {
  @include ehrlo-theme(
    $oh-early-learning-primary,
    $black,
    $oh-early-learning-feature-1,
    $oh-early-learning-feature-2,
    $black
  );

  .icon-image {
    filter: brightness(0) saturate(100%) invert(46%) sepia(52%) saturate(629%)
      hue-rotate(144deg) brightness(103%) contrast(103%);
  }

  .homepage-banner .feature-bg .feature-bg-img {
    background-image: url(../images/banner-images/leaf.svg); // darker leaf image
  }
}

[data-bs-theme='housing'] {
  @include ehrlo-theme(
    $oh-housing-primary,
    $white,
    $oh-housing-feature-1,
    $oh-housing-primary,
    $white
  );

  .icon-image {
    filter: brightness(0) saturate(100%) invert(21%) sepia(51%) saturate(2265%)
      hue-rotate(271deg) brightness(92%) contrast(95%);
  }

  svg {
    fill: $oh-housing-primary;
  }
}

[data-bs-theme='sports'] {
  @include ehrlo-theme(
    $oh-sports-primary,
    $gray-900,
    $oh-sports-primary,
    $oh-sports-primary,
    $gray-900
  );

  .icon-image {
    filter: brightness(0) saturate(100%) invert(76%) sepia(40%) saturate(3056%)
      hue-rotate(341deg) brightness(101%) contrast(98%);
  }
}

.homepage-top {
  margin-bottom: -2rem;
  position: relative;
  z-index: -1;

  .homepage-top-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 90%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      transform: translate(-50%, -50%);
    }
  }

  h1 {
    @include font-size($font-size-base * 4);
    line-height: 1.203em;
    font-weight: 900;
    color: $white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }

  iframe {
    width: 100%;
    aspect-ratio: 6.4/3.6;
    object-fit: cover;
    pointer-events: none;
    overflow: hidden;
  }
}

.homepage-banner {
  position: relative;
  @include rfs(9rem, padding-bottom);
  @include oh-color-background;

  .homepage-banner-inner {
    @include pulsar-container;
    @include rfs(3rem, padding-top);

    .page-title {
      @include font-size($font-size-base * 3);
      margin-bottom: 1rem;
    }

    .homepage-banner-info {
      margin-bottom: 0;
      color: $oh-light-green;
      @extend .h5;
    }

    p {
      margin-bottom: 2rem;
    }

    .homepage-banner-copy {
      position: relative;
      max-width: 750px;
      padding: 0rem 0rem 3rem;
      margin: auto;
    }

    .homepage-banner-full-width {
      display: grid;
      grid-template-columns: 1fr 1.25fr;
      @include rfs(2rem, gap);
      @include rfs(2rem 0rem 6rem, margin);

      img {
        border-radius: $border-radius-lg;
      }

      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
      }

      .homepage-banner-full-width-text {
        margin: auto;
      }
    }
  }

  :first-child {
    &.homepage-banner-links-container {
      .homepage-banner-link {
        @include media-breakpoint-up(sm) {
          transform: translateY(-50%);

          @include interactive {
            transform: translateY(-50%) scale(1.05);
          }
        }
      }
    }
  }

  &:has(#homepage-swiper) {
    @include rfs(0.5rem, padding-bottom);
    @include rfs(0rem, margin-bottom);

    .feature-bg-img {
      top: 0%;
    }
  }

  .feature-bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    clip-path: ellipse(clamp(900px, 110%, 1980px) 85% at 50% 0%);
    border-radius: $border-radius-xl $border-radius-xl 0 0;
    z-index: -1;
    overflow: hidden;

    &.feature-bg-video {
      clip-path: ellipse(clamp(900px, 110%, 1980px) 95% at 50% 0%);
    }

    .feature-bg-img {
      position: absolute;
      left: 25%;
      top: -10%;
      width: 800px;
      height: 804px;
      transform: translate(-65%, 0%);
      z-index: 0;
      pointer-events: none;
      user-select: none;

      @include media-breakpoint-down(lg) {
        top: 0%;
        left: 0%;
        transform: translate(-20%, 0%);
      }
    }
  }
}

.homepage-banner-links-container + .homepage-banner-inner {
  @include rfs(1rem, padding-top);
}

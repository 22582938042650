html.no-js {
  .swiper {
    overflow: visible;

    .swiper-wrapper {
      @include oh-tiles-grid(3);

      .swiper-slide {
        margin-left: 2rem;
        margin: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

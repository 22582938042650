.contact-location {
  @include pulsar-container;
  @include rfs(4.25rem, margin-bottom);

  h3 {
    margin-bottom: 1.5rem;
  }

  .contact-location-inner {
    @include rfs(3rem 4rem, padding);
    box-shadow: 0px 0px 20px rgba($oh-card-shadow, 0.35);
    display: grid;
    @include rfs(6rem, gap);
    grid-template-columns: 5fr 4fr;
    border-radius: $border-radius-lg;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
      @include rfs(2rem 1.5rem, padding);
      @include rfs(3rem, gap);
    }

    .location {
      display: flex;
      flex-direction: column;

      iframe {
        border-radius: 1rem;
        margin-top: 0.5rem;
        height: 100%;
        width: 100%;
        min-height: 300px;
      }
    }
  }
}

.image-swiper {
  background-color: $gray-200;
  border-radius: $border-radius-lg;
  margin-bottom: 2rem;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    .swiper-slide__wrapper {
      min-height: 500px;
      height: 100%;
      padding: 1rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;

      .swiper-slide__image {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          object-fit: contain;
          border-radius: $border-radius-lg;
          overflow: hidden;
        }
      }

      .swiper-slide__caption {
        width: fit-content;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: rgba($body-color, 0.8);
    height: 64px;
    width: 48px;
    border-radius: 0.5rem;

    &:after {
      color: $white;
      font-size: 1.5rem;
      font-weight: 900;
    }
  }

  @include media-breakpoint-up(md) {
    .swiper-slide {
      .swiper-slide__wrapper {
        .swiper-slide__image {
          padding: 0 3.25rem;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.thumb-swiper {
  .swiper-slide {
    cursor: pointer;

    img {
      width: 100%;
      aspect-ratio: 1.5;
      background-color: $gray-200;
      border-radius: $border-radius-lg;
      transition: all 0.3s ease-in-out;
      object-fit: contain;
    }

    &:not(.swiper-slide-thumb-active):not(:hover) {
      img {
        filter: brightness(0.5);
      }
    }
  }

  @include media-breakpoint-up(md) {
    .swiper-slide {
      max-width: 25%;
    }
  }
}

// Variables
$gray-100: #f8f9fa !default;
$gray-600: #6c757d !default;
$gray-900: #212529 !default;

$primary: $oh-green !default;

$body-color: $gray-900 !default;

$border-radius-lg: 1rem;
$border-radius-xl: 1.5rem;
$border-radius-xxl: 3.75rem;
$font-family-base: 'Inter', sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base !default;

$small-font-size: $font-size-base * 0.8125;

$btn-font-weight: 500;
$btn-padding-y: 0.4rem;
$btn-padding-x: 1.35rem;
$btn-hover-border-shade-amount: 15%;
$btn-border-radius: 0.25rem;

$form-check-input-width: 1.35em;

$form-check-margin-bottom: 0.5rem;

$form-check-input-border: 1px solid $gray-600;
$form-check-input-checked-bg-color: $body-color;
$form-check-input-checked-border-color: $body-color;

$navbar-light-icon-color: $primary;

// scss-docs-start card-variables
$card-spacer-y: 2rem;
$card-spacer-x: 1.5rem;
$card-border-width: 0px;
$card-border-color: transparent;
$card-border-radius: $border-radius-lg;

$offcanvas-border-width: 0px !default;

$pagination-border-radius: 0px;

$alert-border-width: 0px;

$container-padding-x: 2rem;

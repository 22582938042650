footer {
  .footer-upper {
    padding: 2.5rem 0 2rem;
    background-color: $gray-800;
  }

  .footer-upper-inner {
    @include pulsar-container;
    display: grid;
    grid-template-columns: 280px 1fr 200px;

    > div {
      &:not(:last-child) {
        border-right: 1px solid $white;
      }

      &:nth-child(2) {
        @include rfs(1rem 6rem 2rem, padding);

        @include media-breakpoint-only(lg) {
          @include rfs(0.5rem 2rem, padding);
        }

        p {
          font-size: 0.815rem;
          line-height: 2em;
          max-width: 443px;
          margin: auto;
        }
      }

      &:nth-child(3) {
        padding: 0rem 0rem 0rem 2.4rem;
        display: flex;
        flex-direction: column;
        @include oh-color-background;

        * {
          color: $white;
        }

        .btn {
          width: 100%;
          color: $primary;
          padding: 0.5rem 1rem;
          font-weight: 500;

          @include interactive {
            color: $white;
          }
        }
      }
    }

    h6 {
      color: $white;
    }

    p {
      color: $white;
      margin-bottom: 0.5rem;
    }

    .contact-number {
      color: $oh-light-green;
    }

    .social-links {
      @include rfs(3.5rem, margin-top);

      .social-link-text {
        @include visually-hidden;
      }

      a {
        color: $white;
        font-size: 1.25rem;
        margin-right: 0.5rem;
        line-height: 1em;
        text-decoration: none;

        @include interactive {
          color: $oh-light-green;
        }
      }
    }

    .accreditation {
      margin-top: auto;

      .accreditation-icons {
        display: flex;
        gap: 1.5rem;
        margin-top: 1.25rem;
      }
    }

    @include media-breakpoint-between(sm, lg) {
      grid-template-columns: 1fr 1fr;
      @include rfs(2rem, row-gap);

      > div {
        &:nth-child(2) {
          order: 1;
          grid-column: span 2;
          border-right: none;
          border-bottom: 1px solid $white;
        }

        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(3) {
          order: 3;

          .btn {
            width: fit-content;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      row-gap: 1rem;
      justify-content: center;
      text-align: center;

      > div {
        padding-bottom: 1rem;

        &:nth-child(1) {
          border-right: none;
          border-bottom: 1px solid $white;
        }

        &:nth-child(2) {
          border-right: none;
          border-bottom: 1px solid $white;
        }

        &:nth-child(3) {
          align-items: center;
          padding: 0;

          .btn {
            margin: auto;
            width: fit-content;
          }
          .accreditation {
            text-align: center;
            margin-top: 1rem;

            .accreditation-icons {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .footer-lower {
    background-color: $body-color;
    padding: 1rem;

    .footer-lower-inner {
      @include pulsar-container;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      gap: 1rem;

      a {
        font-size: $small-font-size;
        color: $oh-light-green;

        &:not(:hover) {
          color: $white;
        }
      }

      .caption {
        color: $white;
        font-size: $small-font-size;
        margin: 0px;
      }
    }
  }

  h5 {
    color: white;
    margin-bottom: 1rem;
  }
}

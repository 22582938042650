// Custom global variables
// NOTE: all custom variables should be prefixed with "oh-"
// to distinguish them from Bootstrap and avoid naming collisions
$oh-med-green: #025f4c;
$oh-green: #008066;
$oh-light-green: #afc752;
$oh-card-shadow: #3b3330;

$oh-primary: $oh-med-green;

$oh-light-grey: #dee2e6;

$oh-counsel-primary: #007575;
$oh-counsel-feature: #fec862;

$oh-early-learning-primary: #0e8494;
$oh-early-learning-feature-1: #17a1c0;
$oh-early-learning-feature-2: #107e90;

$oh-housing-primary: #802a85;
$oh-housing-feature-1: #430147;

$oh-sports-primary: #fda223;

$oh-block-padding: 5.5rem;

$oh-border-radius: 1.5rem;
$oh-border-radius-xxxl: 19rem;

// ----- container width and padding for pulsar container mixins
$oh-container-width-large: 1140px;
$oh-container-width-medium: 946px;
$oh-container-width-small: 750px;

// bootstrap variable overrides
@import 'bootstrap/variables';

// NOTE: HTML smooth scrolling messes with other functionality
// like anchor tags, form validation, tabbing through links, etc.
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

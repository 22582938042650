html {
  height: 100%;
}

body {
  height: 100%;
}

#wrap--full {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;

  #wrap--content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    main {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

img {
  display: block;
  @include img-fluid;
}

.page-content {
  &.page-content__row {
    display: grid;

    &.page-content__row--one_column {
      grid-template-columns: 1fr;
    }

    &.page-content__row--two_column {
      grid-template-columns: 1fr 1fr;
    }

    &.page-content__row--three_column {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.page-content__row--sidebar_left {
      grid-template-columns: 1fr 3fr;
    }

    &.page-content__row--sidebar_right {
      grid-template-columns: 3fr 1fr;
    }
  }
}

// creating custom placeholders that act the same as h1 and .h1
// but avoid problems with extending classes
%oh-h1 {
  @extend %heading;
  @include font-size($h1-font-size);
}

%oh-h2 {
  @extend %heading;
  @include font-size($h2-font-size);
}

%oh-h3 {
  @extend %heading;
  @include font-size($h3-font-size);
}

%oh-h4 {
  @extend %heading;
  @include font-size($h4-font-size);
}

%oh-h5 {
  @extend %heading;
  @include font-size($h5-font-size);
}

%oh-h6 {
  @extend %heading;
  @include font-size($h6-font-size);
}

// custom heading styles that apply to all headings
%oh-heading {
  text-transform: none;
  font-weight: 700;
}

// custom heading styles for specific headings

h1,
.h1,
%oh-h1 {
  @extend %oh-heading;
  line-height: 1.208em;
}

h2,
.h2,
%oh-h2 {
  @extend %oh-heading;
}

h3,
.h3,
%oh-h3 {
  @extend %oh-heading;
}

h4,
.h4,
%oh-h4 {
  @extend %oh-heading;
}

h5,
.h5,
%oh-h5 {
  @extend %oh-heading;
}

h6,
.h6,
%oh-h6 {
  @extend %oh-heading;
}

p {
  line-height: 1.5em;
  font-weight: 300;
}

.blockquote {
  @extend %oh-h5;
  margin: 0rem auto 1.5rem;
  color: $oh-primary;
  font-weight: 500;
  line-height: 1.5em;
}

blockquote {
  @extend .blockquote;
}

table {
  @extend .table;
}

.oh-fancybox-div {
  display: none;
}

a {
  color: tint-color($primary, 15%);
  transition-duration: 0.3s;

  @include interactive {
    color: shade-color($primary, 30%);
  }
}

.btn-primary {
  @include button-variant($primary, $primary);

  @include interactive {
    color: $white;
  }
}

.btn-accent {
  @include button-variant($oh-primary, $oh-primary);

  @include interactive {
    color: $white;
  }
}

//------------- Nav styles
.nav-item {
  .dropdown-menu {
    border-radius: 0;
    border: none;
    margin-top: 0.25rem;
    box-shadow: 0 2px 6px rgba($black, 50%);

    .dropdown-item {
      border-radius: 2px;
      padding-top: 8px;
      padding-bottom: 8px;

      @include media-breakpoint-down(lg) {
        white-space: normal;
      }

      @include interactive {
        background-color: $oh-light-green;
        color: $body-color;
      }
    }

    li {
      padding: 0 0.5rem;
    }
  }
}

.header-nav {
  .offcanvas-body {
    .navbar-nav {
      align-items: center;

      .nav-item {
        padding: 0rem 0.75rem;

        .dropdown-menu {
          border-radius: 2px;
        }

        .nav-link {
          font-weight: 400;
          color: $body-color;
          padding: 0.5rem 0rem;

          @include media-breakpoint-only(lg) {
            font-size: 0.88rem;
          }

          @include interactive {
            color: $primary;
          }

          &.dropdown-toggle {
            &::after {
              @include fa-pseudo-solid('\f078');
              border: none;
              color: $primary;
              vertical-align: unset;
            }
          }
        }
      }
    }

    .nav-search-link {
      a {
        font-size: 1.5rem;
        color: $body-color;

        @include interactive {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    @include media-breakpoint-down(lg) {
      .nav-search-link {
        display: none;
      }

      .navbar-nav {
        align-items: flex-start;
        gap: 1rem;

        .btn-close {
          color: $body-color;
        }

        .nav-item {
          width: 100%;
          padding: 0;

          .nav-link {
            font-size: 1rem;
            padding: 0.15rem 0rem;
            width: fit-content;
          }
        }
      }
    }
  }

  .navbar-toggler {
    border: none;

    .navbar-toggler-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .search-icon {
    padding: 0.5rem 0rem;
    color: $body-color;
    transition: all 0.3s ease-in-out;
    margin-left: 0.5rem;

    @include interactive {
      color: $primary;
    }
  }
}

.accordion {
  .accordion-item {
    border-left: none;
    border-right: none;
  }

  .accordion-header {
    .accordion-button {
      background-color: transparent;
      margin-bottom: 0;
      border: none;
    }

    button {
      padding-left: 0;
      padding-right: 0;
      @extend .h5;
    }
  }

  .accordion-body {
    padding-left: 0;
    padding-right: 0;

    p {
      margin: 0;
    }
  }
}

.table {
  thead {
    border-color: $body-color;
  }

  th,
  td {
    padding-left: 0;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.main-header {
  @include rfs(1rem, padding-top);
  @include rfs(1rem, padding-bottom);
  position: relative;

  .offcanvas.offcanvas-end.full-screen {
    &.show:not(.hiding) + .mega-menu-container,
    &.showing + .mega-menu-container {
      .bar {
        &:nth-of-type(1) {
          transform: rotate(26.565deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(-26.565deg);
        }
      }
    }
  }

  .main-header-upper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    gap: 2rem;
    align-items: center;
    z-index: 5;

    .menu {
      width: 48px;
      position: relative;
      @include border-radius(100%);
      @include transition(0.3s);
      cursor: pointer;
    }
    .bar {
      height: 2px;
      width: 48px;
      display: block;
      margin: 9px auto;
      position: relative;
      background-color: $oh-light-green;
      @include border-radius(3px);
      @include transition(0.4s);
      transform-origin: left;
    }

    .mega-menu-container {
      z-index: 2048;
      position: relative;
    }

    .donate-button {
      &:after {
        @include fa-pseudo-solid('\f004');
        margin-left: 5px;
      }
    }
  }

  .mega-menu-container-outer {
    border-radius: 0 0 0 $oh-border-radius;
    background-color: $gray-800;
    padding: 1rem 0px;
    display: flex;
  }

  .mega-menu-left-link {
    padding: 0.25rem 2rem;
    display: flex;
    align-items: center;
    border-right: 1px solid $white;

    .mega-menu-back {
      @extend .h6;
      margin: 0;
      color: $white;
      text-decoration: none;
      @include arrow-link-left($oh-light-green);
      @include interactive {
        color: $oh-light-green;
      }
    }
  }

  .mega-menu-container {
    @include rfs(4rem, padding-left);
    display: flex;
    padding: 0.25rem 0px;
    gap: 1.25rem;
    color: $white;
    align-items: center;
    width: 477px;

    .mega-menu-button-text {
      @include visually-hidden;
    }

    h6 {
      margin: 0;
    }

    button {
      background: none;
      padding: 0;
      border: 0;
      @include rfs(3rem, width);
    }
  }

  .offcanvas.full-screen {
    width: 100vw;

    .offcanvas-body {
      padding: 0;
      display: grid;
      grid-template-columns: auto 509px;
      overflow-x: hidden;

      // NOTE: .offcanvas-right comes before .offcanvas-left in the DOM
      // these elements have been given an `order` property in CSS

      .offcanvas-left {
        display: flex;
        padding: 2rem;
        background-color: var(--primary);
        order: -1;

        .offcanvas-left-body {
          display: block;
          width: 90%;
          max-width: 640px;
          margin: auto;
          color: var(--text-color);

          svg {
            margin-bottom: 1rem;
            fill: var(--text-color);
          }

          h1 {
            @include font-size($font-size-base * 3);
            margin-bottom: 1.5rem;
          }

          p {
            @include font-size($font-size-base * 1.25);
            line-height: 2.05em;
            margin-bottom: 1.5rem;
          }

          a {
            @include font-size($font-size-base * 1.25);
            color: var(--text-color);
            text-decoration: none;

            &:after {
              margin-left: 0.5rem;
              @include fa-pseudo-solid('\f105');
              transition: all 0.3s ease-in-out;
            }

            @include interactive {
              &:after {
                margin-left: 0.75rem;
              }
            }
          }
        }
      }

      .offcanvas-right {
        background-color: $gray-800;
        color: $white;
        display: grid;
        overflow: hidden;
        position: relative;
        @include rfs(6rem, padding-left);
        order: 1;

        .offcanvas-right-body {
          display: flex;
          position: relative;
          padding: 1rem 0rem;
          z-index: 1;

          .offcanvas-right-body-inner {
            margin: auto 0;
            display: grid;
            gap: 3rem;
          }

          a {
            display: block;
            @extend .h3;
            color: $white;
            text-decoration: none;
            position: relative;
            width: fit-content;
            margin-bottom: 0;

            &::after {
              content: '';
              background-color: $white;
              width: 0%;
              height: 2px;
              position: absolute;
              left: 0;
              bottom: -0.5rem;
              transition: all 0.3s ease-in-out;
            }

            @include interactive {
              color: $oh-light-green;

              &::after {
                width: 100%;
                background-color: $oh-light-green;
              }
            }
          }
        }

        .offcanvas-right-bg {
          position: absolute;
          bottom: 0%;
          width: 100%;
          z-index: 0;
          pointer-events: none;
          user-select: none;

          img {
            transform: translate(15%, 20%);
          }
        }
      }
    }
  }

  .header-inner {
    @include pulsar-container();
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .header-logo {
      img {
        max-height: 8rem;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .mega-menu-container {
      width: 309px;
      @include rfs(1rem, padding-left);
    }

    .offcanvas.full-screen {
      .offcanvas-body {
        grid-template-columns: 1fr;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .header-content {
      margin-top: auto;
    }

    .header-inner {
      min-height: 10rem;
    }
  }

  @include media-breakpoint-only(lg) {
    .header-inner {
      gap: 1rem;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 0;

    .main-header-upper {
      position: relative;
      width: 100%;
      justify-content: flex-end;
      padding-bottom: 1rem;
    }
  }

  @include media-breakpoint-only(md) {
    .header-inner {
      position: relative;
    }

    .header-nav {
      position: absolute;
      right: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    .header-content {
      margin-top: auto;
    }

    .mega-menu-left-link {
      display: none;
    }

    .mega-menu-container-outer {
      padding: 1rem;
      padding-right: 2rem;
    }

    .main-header-upper {
      .mega-menu-container {
        width: auto;

        h6 {
          @include font-size(12px);
        }
      }
    }

    .offcanvas.full-screen {
      .offcanvas-body {
        grid-template-columns: 1fr;

        .offcanvas-left {
          padding: 1rem;

          .offcanvas-left-body {
            margin-top: auto;

            svg {
              max-width: 50px;
            }

            h1 {
              @include font-size(22px);
              margin-bottom: 1rem;
            }

            p {
              @include font-size(16px);
              line-height: 1.75em;
              margin-bottom: 1rem;
            }

            a {
              @include font-size(18px);
            }
          }
        }

        .offcanvas-right {
          order: -1;
          overflow: visible;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 4rem;

          .offcanvas-right-body {
            @include rfs(0rem, margin-top);
            display: flex;
            width: 90%;
            margin: auto;
            padding-bottom: 2rem;

            .offcanvas-right-body-inner {
              margin: auto 0;
              grid-template-columns: 1fr;
              gap: 1.5rem 5rem;

              a {
                @include font-size(20px);
              }
            }
          }

          .offcanvas-right-bg {
            max-height: 100%;
            left: 0;
            overflow: hidden;

            img {
              max-width: 60%;
              margin-left: auto;
              transform: translate(13%, 25%);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-between(md, xl) {
    .offcanvas.full-screen {
      .offcanvas-body {
        grid-template-columns: auto 309px;

        .offcanvas-right {
          padding-left: 2rem;

          .offcanvas-right-body {
            .offcanvas-right-body-inner {
              a {
                @include font-size($font-size-base * 1.25);
              }
            }
          }
        }
      }
    }
  }
}

//General inner template
ul {
  padding-left: 20px;

  li {
    padding-left: clamp(0.25rem, 0.1vw, 0.75rem);
    padding-bottom: 0.5rem;
  }

  > ul {
    padding-left: 20px;
    position: relative;

    > li {
      list-style: none;

      &::before {
        content: '-';
        position: absolute;
        left: 0;
      }
    }
  }
}

.breadcrumb-wrap {
  @include pulsar-container;
  @include rfs(0.75rem, margin-top);

  a {
    text-decoration: none;
    color: $oh-primary;

    @include interactive {
      color: $body-color;
    }
  }
}

.content-wrap {
  @include rfs(3rem, margin-top);
  @include rfs(5rem, margin-bottom);

  &,
  &.width--full {
    width: 100%;
  }

  &.top-header {
    margin-bottom: 0;

    + .content-wrap {
      margin-top: 0;
    }
  }

  &.width--large {
    @include pulsar-container($oh-container-width-large);
  }

  &.width--medium {
    @include pulsar-container($oh-container-width-medium);
  }

  &.width--small {
    @include pulsar-container($oh-container-width-small);
  }
}

.content-wrap,
.page-content__wysiwyg {
  > img,
  > p > img {
    border-radius: $border-radius-lg;
  }

  p {
    min-height: 1em;
  }

  img,
  iframe {
    max-width: 100%;
  }

  .align-left {
    float: left;
    margin-right: 1.5rem;
  }

  .align-right {
    float: right;
    margin-left: 1.5rem;
  }
}

.pagination {
  margin: auto;

  .page-item {
    padding: 0;
    padding-bottom: 0;
    padding-left: 0;

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;

      .page-link {
        color: grey;
      }
    }

    &.page-item-next {
      .page-link {
        border-radius: 0 $btn-border-radius $btn-border-radius 0;
      }
    }

    &.page-item-prev {
      .page-link {
        border-radius: $btn-border-radius 0 0 $btn-border-radius;
      }
    }

    &.page-item-next,
    &.page-item-prev {
      .page-link {
        font-size: 0;

        &:after {
          @include fa-pseudo-solid('\f053');
          display: block;
          visibility: visible;
          font-size: 20px;
          line-height: 1.2em;
          height: 100%;
          margin: auto;
        }
      }
    }

    &.page-item-prev .page-link:after {
      content: '\f053';
    }

    &.page-item-next .page-link:after {
      content: '\f054';
    }

    @include interactive {
      .page-link {
        color: white;
        background-color: $primary;
      }
    }

    .page-link {
      &:active,
      &.active {
        border-color: $oh-light-grey;
      }
    }
  }
}

.alert-bar {
  @extend .alert-warning;

  margin: 0;
  background-color: $primary;
  border-radius: 0;
  color: $white;

  .alert-bar-content {
    @include pulsar-container();
  }

  * {
    margin: 0;
  }
}

.mb-3 {
  margin-bottom: 1rem;
}

.d-block {
  display: block;
}

form {
  .col-form-label,
  .form-label {
    font-weight: 400;

    &.required:after {
      content: '\00a0\002A';
    }
  }
}

.section-container {
  @include rfs(2rem, padding-top);
  @include rfs(2rem, padding-bottom);
  max-width: calc(100% - 2rem);
  @include pulsar-container($container-padding: 0px);
}

.card-container {
  @include oh-tiles-grid(3);

  .card {
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba($oh-card-shadow, 0.35);
    transition: all 0.3s ease-in-out;

    @include interactive {
      scale: 1.05;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.5rem;
    }

    .card-title {
      @extend .h3;
      margin-bottom: 0.75rem;
    }

    .card-author {
      font-size: 0.815rem;
      margin-bottom: 2rem;
    }

    .card-date {
      margin: 0;
      margin-bottom: 0rem;
      font-size: 0.815rem;
      color: $primary;
    }

    .card-text {
      margin-bottom: 2.5rem;
    }

    .card-btn {
      margin-top: auto;

      p {
        margin: 0;
      }
    }

    .card-arrow {
      margin-top: auto;

      a {
        @include arrow-link-right();
        color: $body-color;

        @include interactive {
          color: $primary;
        }
      }

      p {
        margin: 0;
      }
    }

    .card-img-top {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.tags {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  .tag {
    background-color: rgba($oh-light-green, 40%);
    display: block;
    padding: 1px 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    border-radius: 2px;
  }
}

.event-wrap {
  .event-title {
    margin-bottom: 1rem;
  }

  .event-date {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .event-img {
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .event-back {
    margin-top: 2rem;

    a {
      font-weight: 700;
      text-decoration: none;

      &:before {
        @include fa-pseudo-solid('\f053');
      }
    }
  }
}

.news-wrap {
  .news-title {
    margin-bottom: 0.5rem;
  }

  .news-author-date {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .news-img {
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    width: 100%;
    margin-bottom: 1.25rem;
  }

  .news-back {
    margin-top: 2rem;

    a {
      font-weight: 700;
      text-decoration: none;

      &:before {
        @include fa-pseudo-solid('\f053');
      }
    }
  }
}

.pagination-nav {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-info {
  text-align: right;
}

// ------------------ Ranch Ehrlo Custom Styles ------------------ //
.homepage-banner-links-container {
  max-width: calc(100% - 2rem);
  @include pulsar-container($container-padding: 0px);
  @include rfs(-6rem, margin-bottom);
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    @include rfs(0rem, margin-bottom);
  }

  .homepage-banner-links {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      padding-top: 1.5rem;
    }

    .homepage-banner-link {
      padding: 3rem 3.5rem;
      gap: 1.75rem;
      justify-content: left;
      background-color: white;
      border-radius: $border-radius-xxl 0px;
      display: grid;
      grid-template-columns: clamp(54px, calc(3rem + 2.125vw), 76.5px) auto;
      justify-content: center;
      box-shadow: 0px 0px 20px rgba($oh-card-shadow, 0.6);
      min-width: calc(50% - 1rem);
      @include svg-shaking-card();

      @include media-breakpoint-down(xl) {
        max-width: 580px;
        padding: 3rem 1.5rem;
        gap: 1.5rem;
        flex-grow: 1;
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: calc(2rem + 5.5vw) auto;
        gap: 1rem;
      }

      > img,
      > svg {
        height: clamp(54px, calc(3rem + 2.125vw), 76.5px);
        width: clamp(54px, calc(3rem + 2.125vw), 76.5px);
        object-fit: contain;
        object-position: center;
      }

      .homepage-banner-link-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          color: $black;
          max-width: 100%;
          text-overflow: ellipsis;
          margin-bottom: 0rem;
        }

        a {
          @include arrow-link-right();
          @include font-size(1.25rem);
          color: $body-color;
          margin-top: auto;
          line-height: 1em;
          margin-bottom: -0.5em;

          @include interactive {
            color: $primary;
          }
        }

        @include media-breakpoint-down(sm) {
          h3 {
            font-size: calc(1rem + 0.9vw);
          }

          a {
            font-size: calc(0.8rem + 0.6vw);
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 0;

      .homepage-banner-link {
        transform: translateY(0%);
      }
    }
  }
}

.feature-programs {
  @include rfs(1rem, margin-top);
  @include rfs(5rem, margin-bottom);
  @include pulsar-container();

  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 2.5rem;
  }

  h3 {
    margin-bottom: 1.25rem;
  }
}

#homepage-swiper {
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  .swiper-style-card {
    height: unset;

    @include media-breakpoint-up(lg) {
      max-width: 380px;
      width: 380px;
    }
  }

  .swiper-button-prev {
    &::after {
      @include fa-pseudo-solid('\f060');
    }
  }

  .swiper-button-next {
    &::after {
      @include fa-pseudo-solid('\f061');
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 1.5rem;
    color: $body-color;
    padding: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    background-color: $gray-300;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    @include interactive {
      background-color: $body-color;
      color: $white;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      height: 2rem;
      width: 2rem;
    }
  }

  .swiper-button-prev {
    transform: translateX(-65%);
  }

  .swiper-button-next {
    transform: translateX(65%);
  }
}

.swiper-style-card {
  background-color: $white;
  height: 100%;
  min-height: fit-content;
  width: 100%;
  border-radius: $border-radius-xxl 0px 0px 0px;
  @include rfs(3rem, padding);
  @include rfs(5rem, padding-top);
  box-shadow: 0px 0px 20px rgba($oh-card-shadow, 0.35);
  border-bottom: 16px solid var(--primary);
  display: flex;
  flex-direction: column;
  @include svg-shaking-card();

  %oh-heading,
  p {
    color: $body-color;
  }

  h3 {
    max-width: 90%;
    margin-bottom: 1.25rem;
  }

  svg {
    width: 63px;
    height: 63px;
    margin-bottom: 1rem;
  }

  .explore-link {
    font-size: 1.25rem;
    color: $body-color;
    text-decoration: none;
    margin-top: auto;
    @include arrow-link-right();

    &::after {
      font-size: 1rem;
    }

    @include interactive {
      color: $primary;
    }
  }
}

.thumb-swiper {
  .swiper-wrapper.swiper-logos {
    align-items: center;

    .swiper-slide {
      img {
        max-height: 150px;
        aspect-ratio: unset;
        object-fit: contain;
      }

      &:not(.swiper-slide-thumb-active):not(:hover) {
        img {
          filter: opacity(0.75);
        }
      }
    }
  }
}

.news-section {
  @include rfs(1.5rem, margin-top);
  @include rfs(4rem, margin-bottom);
  background-color: $gray-100;
  border-radius: $border-radius-xxl $border-radius-xxl 0px 0px;

  .news-section-header {
    @include rfs(3rem, padding-top);
    border-radius: $border-radius-xxl $border-radius-xxl 0px 0px;
    background-color: var(--feature-2);
    padding-bottom: calc(250px + 1.5rem);
    margin-bottom: -250px;

    .news-section-header-inner {
      @include pulsar-container;

      h1 {
        margin-top: 2rem;
        color: var(--feature-text);
      }
    }
  }

  .news-section-body {
    @include pulsar-container;
    @include rfs(6rem, padding-bottom);

    .card-container {
      @include rfs(4rem, margin-bottom);
    }

    .btn {
      margin: auto;
      display: block;
      width: fit-content;
    }
  }
}

.homepage-blocks-section {
  @include rfs(6.5rem, margin-bottom);

  .homepage-block {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    position: relative;
    max-width: 1500px;
    margin: auto;
    margin-bottom: 3rem;

    .homepage-block-inner {
      @include pulsar-container(1084px);
      width: calc(100% - 2rem);
      padding: 0;
      background-color: transparent;
      box-shadow: 0px 0px 20px rgba($oh-card-shadow, 0.35);
      display: grid;
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
      transition-delay: 0s, 0.3s, 0.3s;
      transition-property: scale, opacity, transform;

      @include interactive {
        scale: 1.05;
      }

      .homepage-block-text {
        @include rfs(9rem 4rem, padding);
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          a {
            font-weight: 500;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .homepage-block-link {
          @include arrow-link-right();
          color: $body-color;

          @include interactive {
            color: $primary;
          }
        }
      }

      .homepage-block-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 1.09;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        max-width: 600px;

        .homepage-block-image {
          aspect-ratio: 1.5;
        }

        .homepage-block-text {
          @include rfs(4.5rem 3rem, padding);
        }
      }
    }

    &:before {
      content: '';
      height: 621px;
      width: 600px;
      max-height: 110vw;
      max-width: 90%;
      top: 0;
      z-index: -1;
      position: absolute;
    }

    &:nth-child(odd) {
      .homepage-block-inner {
        border-radius: 0 $border-radius-xxl $border-radius-xxl
          $border-radius-xxl;
      }

      &:before {
        left: 0;
        background-color: var(--feature-1);
        border-radius: 0 $oh-border-radius-xxxl 0 $oh-border-radius-xxxl;
      }
    }

    &:nth-child(even) {
      .homepage-block-inner {
        border-radius: $border-radius-xxl 0 $border-radius-xxl
          $border-radius-xxl;

        @include media-breakpoint-up(lg) {
          .homepage-block-text {
            order: 1;
          }

          .homepage-block-image {
            order: 2;
          }
        }
      }

      &:before {
        right: 0;
        background-color: var(--feature-2);
        border-radius: $oh-border-radius-xxxl 0 $oh-border-radius-xxxl 0;
      }
    }
  }
}

.feature-banner {
  background-color: var(--primary);
  border-radius: $border-radius-xl $border-radius-xl 0 0;
  @include rfs(6rem, padding-top);
  @include rfs(6rem, padding-bottom);
  overflow: hidden;

  .feature-banner-inner {
    @include pulsar-container;
    position: relative;

    img {
      position: absolute;
      left: -10%;
      top: 50%;
      width: 800px;
      transform: translate(0%, -50%);
      z-index: 0;
      pointer-events: none;
      user-select: none;
    }

    .feature-banner-text {
      position: relative;
      color: var(--text-color);
      z-index: 1;

      h1 {
        margin: 0;
      }

      span {
        @extend h1;
      }
    }
  }
}

.referral-program {
  margin-top: auto;
  background-color: $gray-700;
  @include rfs(2.4rem, padding-top);
  @include rfs(2.4rem, padding-bottom);

  .referral-program-inner {
    @include pulsar-container(575px);
    display: grid;
    grid-template-columns: 103px auto;
    @include rfs(2rem, gap);

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      justify-content: center;

      img {
        margin: auto;
      }
      > div {
        width: fit-content;
        width: clamp(320px, 80%, 480px);
        margin: auto;
      }

      h2 {
        margin-bottom: 0.5rem;
        width: fit-content;
      }
    }

    * {
      color: $white;
    }

    svg {
      width: 100%;
      max-width: 120px;
      height: auto;
    }

    .referral-link {
      @include arrow-link-right();

      &::after {
        color: $oh-light-green;
      }
    }
  }
}

.team {
  @include rfs(4rem, padding-top);
  @include rfs(6rem, padding-bottom);
  max-width: calc(100% - 2rem);
  @include pulsar-container($container-padding: 0px);
}

.team-members {
  @include rfs(1.5rem, padding-top);

  .team-member {
    overflow: visible;
    margin-top: 2.5rem;
    text-align: center;

    .card-body {
      padding: 1.5rem 1rem 1rem;
    }

    p {
      margin: 0;
    }

    .card-img-top {
      margin: auto;
      margin-top: -2.5rem;
      border-radius: 50%;
      width: 222px;
      height: unset;
      aspect-ratio: 1;
    }

    .card-title {
      @extend .h5;
    }

    .card-btn {
      margin-top: auto;
      padding-top: 2.5rem;

      .btn {
        width: 100%;
        color: $white;
      }
    }
  }
}

.feature-detail-blocks {
  .feature-detail-block {
    @include rfs(4rem, padding-top);
    @include rfs(6rem, padding-bottom);
    border-radius: $border-radius-xxl $border-radius-xxl 0 0;

    &:nth-child(odd) {
      background-color: rgb(from var(--feature-1) r g b / 0.07);
    }

    .feature-detail-block-inner {
      @include pulsar-container($oh-container-width-medium);

      h2 {
        margin-bottom: 1rem;
      }

      h3 {
        margin-bottom: 1rem;
      }

      > img {
        border-radius: $border-radius-lg;
        aspect-ratio: 2;
        margin-bottom: 2.5rem;
        object-fit: cover;
        width: 100%;
      }

      p {
        margin-bottom: 1.5rem;
      }

      .btn.btn-primary {
        margin-top: 1rem;
        margin-right: 1rem;
        @include button-variant($oh-primary, $oh-primary);
      }

      .btn-map {
        &::before {
          @include fa-pseudo-solid('\f279');
          margin-right: 0.75rem;
          color: $oh-light-green;
        }
      }

      .btn-vr {
        &::before {
          @include fa-pseudo-solid('\f729');
          margin-right: 0.75rem;
          color: $oh-light-green;
        }
      }

      .plyr {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 0.75rem;
      }
    }
  }
}

.fancybox-container {
  width: 95%;
  max-width: 700px;
  padding: 0;
  border-radius: 6px;

  &.fancybox__content > .f-button.is-close-btn {
    top: 0.75rem;
    right: 1.5rem;
    color: $oh-primary;
    font-weight: 900;
    height: 2rem;
    width: 2rem;
    background-color: transparent;
  }

  .fancybox-header {
    height: 3.5rem;
    border-bottom: 1px solid rgba($black, 0.12);
  }

  .fancybox-body {
    padding: 2rem 2rem 2.5rem;

    .fancybox-member {
      display: grid;
      grid-template-columns: 222px auto;
      gap: 2rem;
      align-items: center;
      @include rfs(2.5rem, margin-bottom);

      img {
        border-radius: 50%;
        aspect-ratio: 1;
        width: 100%;
        max-width: 260px;
        margin: auto;
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .fancybox-footer {
    padding: 1.5rem 2rem 1.5rem;
    border-top: 1px solid rgba($black, 0.12);
  }
}

.campus-contact {
  padding: 1.5rem 0;

  .campus-contact-tiles {
    @extend .card-container;
  }

  .campus-contact-item {
    @extend .card;

    .campus-contact-item__title {
      margin-bottom: 0.75rem;
    }

    .campus-contact-item__body {
      @extend .card-body;
    }

    .campus-contact-item__location {
      margin-bottom: 1rem;

      p {
        margin: 0;
      }
    }
  }
}

.newsletter-signup {
  margin-top: auto;
  background-color: $gray-700;
  @include rfs(2.4rem, padding-top);
  @include rfs(2.4rem, padding-bottom);

  .newsletter-signup__inner {
    @include pulsar-container(575px);
    color: $white;
  }

  .newsletter-signup__icon {
    @include font-size(60px);
    line-height: 1em;
    margin-bottom: 1rem;
    text-align: center;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .mc-field-group {
    margin-bottom: 1rem;
  }

  #mc_embed_signup input.mce_inline_error {
    border-color: $warning;
  }

  #mc_embed_signup div.mce_inline_error {
    background-color: $warning;
    color: $body-color;
    border-radius: 6px;
    margin: 0;
    margin-top: 0.25rem !important;
    font-size: 12px;
  }

  .btn-submit {
    width: 100%;
    margin-top: 0.5rem;
    border-radius: $border-radius;
  }

  @include media-breakpoint-up(sm) {
    .first-last-name {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
}
